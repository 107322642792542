import { getAuth, signInAnonymously, signInWithCustomToken } from "firebase/auth";

const slugify = (str) =>
  String(str)
    .normalize("NFKD")
    .replace(/[\u0300-\u036F]/g, "")
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, "-")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");

export const useSessionStore = defineStore("session", {
  state: () => ({
    _isLoading: false,
    _isAuthenticated: undefined,
    _isAnonymous: undefined,
    _isLoaded: false,
    _jwt: undefined,
    _email: undefined,
    _certificate: undefined,
    _currentUser: undefined,
    _orgName: undefined,
    _paths: undefined,
    _seatId: undefined,
    _error: undefined,
  }),
  getters: {
    jwt: (state) => state._jwt,
    isLoading: (state) => state._isLoading,
    isAuthenticated: (state) => state._isAuthenticated,
    isAnonymous: (state) => state._currentUser?.isAnonymous,
    isLoaded: (state) => state._isLoaded,
    currentUser: (state) => state._currentUser,
    name: (state) => state._currentUser?.displayName,
    firebaseUserId: (state) => state._currentUser?.uid,
    email: (state) => state._email,
    orgName: (state) => state._orgName,
    orgId: (state) => slugify(state._orgName),
    paths: (state) => state._paths,
    certificate: (state) => state._certificate,
    seatId: (state) => state._seatId,
    error: (state) => state._error,
  },
  actions: {
    async finalizeSetup(name) {
      const auth = getAuth();
      await auth.currentUser.getIdToken(true).then(async (idToken) => {
        await useAsyncData("finalize", () => {
          $fetch("/api/course/finalize", {
            method: "POST",
            body: JSON.stringify({ email: this.email, seatId: this.seatId, name }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
          }).then(async () => {
            await this.loadSession(this.seatId);
          });
        });
      });
    },
    async reloadSession() {
      await this.loadSession(this.seatId);
    },
    async loadSession(seatId) {
      this._isLoading = true;
      this._seatId = seatId;

      await useAsyncData("session", () => {
        $fetch("/api/course/session", {
          method: "POST",
          body: JSON.stringify({ seatId }),
        })
          .then(async (res) => {
            const auth = getAuth();
            const { jwt, email, companyName: orgName, paths, certificate } = res;

            this.$patch({
              _jwt: jwt,
              _email: email,
              _orgName: orgName,
              _certificate: certificate,
              _paths: {
                org: paths.org,
                certificate: paths.certificate,
                invitation: paths.invitation,
                training: paths.training,
                legacy: {
                  account: paths.legacy.account,
                  profile: paths.legacy.profile,
                },
              },
            });
            if (!jwt) {
              await signInAnonymously(auth);
            } else {
              await signInWithCustomToken(auth, jwt);
            }
            this._isLoading = false;
            this._isLoaded = true;
            this._isAuthenticated = true;
            this._currentUser = auth.currentUser;
          })
          .catch(() => {
            this._isAuthenticated = false;
            this._isLoaded = true;
            this._isLoading = false;
          });
      });
    },
    async completeAssessment() {
      await useAsyncData("certificate", () =>
        $fetch("/api/course/complete", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            firebaseUserId: this.firebaseUserId,
            email: this.email,
          }),
        }).then((res) => {
          this.$patch({
            _certificate: res,
          });
        }),
      );
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "session",
        storage: persistedState.localStorage,
      },
    ],
  },
});
